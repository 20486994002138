import { useEffect } from "react"
import { Navbar, Home, Thankyou } from "./components/home";
import { FullScreenLoader } from "./components/utils/loader";
import { useLocation, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function initializeReactGA(){
  ReactGA.initialize("UA-205597240-3");
  ReactGA.pageview("/")
}

function App() {
  useEffect(() => {
    initializeReactGA();
  }, []);

  const query = useQuery();

  const utmMedium = query.get("utm_medium");
  const utmSource = query.get("utm_source");
  const utmCampaign = query.get("utm_campaign");
  const salesPersonId = query.get("id");

  return (
    <div className="App">
      <FullScreenLoader />
      <header>
        <Navbar />
      </header>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
              salesPersonId={salesPersonId}
            />
          }
        />
        <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
    </div>
  );
}

export default App;
