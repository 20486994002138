import axios from "axios"

const BACKEND_URL = process.env.REACT_APP_BACKEND_BASE_URL

export function validateName(name) {
  if (name !== "") {
    let res = /^[a-zA-Z ]+$/.test(name);
    if (res) {
      return "";
    } else {
      return "Use only letters for name";
    }
  } else {
    return "Name is required";
  }
}

function checkEmailFormat(email) {
  const res =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      String(email).toLowerCase()
    );
  return res;
}

export async function validateEmail(email) {
  if (email !== "") {
    if (checkEmailFormat(email)) {
      let result = await emailAvailable(email);
      if (result) {
        return "";
      } else {
        return "Email already exists";
      }
    } else {
      return "Enter a valid email";
    }
  } else {
    return "Email is required";
  }
}

async function emailAvailable(email) {
  try {
    const { status, data } = await axios.post(
      `https://lancet-learnhat.herokuapp.com/api/auth/email-check`,
      {
        email,
      }
    );
    if (status === 200 && data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

function checkPhoneNoFormat(phoneNo) {
  if(phoneNo.length >= 10){
    const res = /^[0-9]+$/.test(phoneNo);
    return res;
  }else return false;
}

export async function validatePartnerPhoneNo(phoneNo) {
  if (phoneNo !== "") {
    if (checkPhoneNoFormat(phoneNo)) {
      let result = await phoneNoAvailable(phoneNo);
      if (result) {
        return "";
      } else {
        return "Phone number exists";
      }
    } else {
      return "Enter a valid phone number";
    }
  } else {
    return "Phone number is required";
  }
}

async function phoneNoAvailable(phoneNo) {
  try {
    const { status, data } = await axios.post(
      `${BACKEND_URL}/auth/partner-phone-check`,
      {
        phoneNumber: phoneNo,
      }
    );
    if (status === 200 && data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function validateClass(classStudying){
  if(classStudying !== ""){
    return ""
  }else{ 
    return "Class is required"
  }
}

export function validateCity(city) {
  if (city !== "") {
    return "";
  } else {
    return "City is required";
  }
}

export function validateState(stateFrom){
  if(stateFrom !== ""){
    return ""
  }else{ 
    return "State is required"
  }
}

export function validateOccupation(occupation) {
  if (occupation !== "") {
    return "";
  } else {
    return "Primary Occupation is required";
  }
}

export function validateExperience(exp) {
  if (exp !== "") {
    return "";
  } else {
    return "Past experience info is required";
  }
}

export function validateExperienceDesc(expDesc) {
  if (expDesc !== "") {
    return "";
  } else {
    return "A short description on experience is required";
  }
}