/* eslint-disable */

import { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Home.module.css";
import { InputText, InputSelect } from "../utils/input";
import { PurpleButton } from "../utils/buttons";
import {
  validateName,
  validatePartnerPhoneNo,
  validateOccupation,
  validateState,
  validateCity,
  validateExperience,
  validateExperienceDesc,
} from "../utils/validations";
import { useToast } from "@chakra-ui/react";
import { useLoading } from "../utils/loader";
import axios from "axios";
import { useNavigate } from "react-router-dom"

const statesAndUts = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman & Nicobar Islands",
  "Dadra and Nagar Haveli and Daman & Diu",
  "Jammu & Kashmir",
  "Lakshadweep",
  "Chandigarh",
  "Delhi",
  "Ladakh",
  "Puducherry",
];

const Form = ({ utmCampaign, utmSource, utmMedium, salesPersonId }) => {

  const [name, setName] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [occupation, setOccupation] = useState("");
  const [experience, setExperience] = useState("");
  const [expDesc, setExpDesc] = useState("");
  const [city, setCity] = useState("");
  const [stateFrom, setStateFrom] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(true);

  const [nameValidationMsg, setNameValidationMsg] = useState("");
  const [phoneValidationMsg, setPhoneValidationMsg] = useState("");
  const [occupationValidationMsg, setOccupationValidationMsg] = useState("");
  const [expValidationMsg, setExpValidationMsg] = useState("");
  const [expDescValidationMsg, setExpDescValidationMsg] = useState("");
  const [cityValidationMsg, setCityValidationMsg] = useState("");
  const [stateValidationMsg, setStateValiadtionMsg] = useState("");

  const [salesPersonName, setSalesPersonName] = useState("")
  const source_adv = `B2B - ${salesPersonName}`;

  const toast = useToast();
  const { setLoadingStatus } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    (async function(){
      try {
         const { status, data } = await axios.get(
           `${process.env.REACT_APP_BACKEND_BASE_URL}/sales-person/${salesPersonId}/detail`
         );
         if (status === 200) {
           setSalesPersonName(data.salesPerson.name);
         }
      } catch (error) {
        console.log(error)
      }
    })()
  },[])

  function handleNameInput(e) {
    setName(e.target.value);
  }

  function handleMobNoInput(e) {
    setMobNo(e.target.value);
  }

  function handleOccupationInput(e) {
    setOccupation(e.target.value);
  }

  function handleExperienceInput(e) {
    setExperience(e.target.value);
  }

  function handleExpDescInput(e) {
    setExpDesc(e.target.value);
  }

  function handleCityInput(e) {
    setCity(e.target.value);
  }

  function handleStateInput(e) {
    setStateFrom(e.target.value);
  }

  function handleAgreedTerms(e) {
    e.target.checked ? setTermsAgreed(true) : setTermsAgreed(false);
  }

  function checkName() {
    setNameValidationMsg(validateName(name));
  }

  async function checkPhoneNo() {
    setPhoneValidationMsg(await validatePartnerPhoneNo(mobNo));
  }

  function checkOccupation() {
    setOccupationValidationMsg(validateOccupation(occupation));
  }

  function checkCity() {
    setCityValidationMsg(validateCity(city));
  }

  function checkState() {
    setStateValiadtionMsg(validateState(stateFrom));
  }

  function checkExperience() {
    setExpValidationMsg(validateExperience(experience));
  }

  function checkExpDesc() {
    setExpDescValidationMsg(validateExperienceDesc(expDesc));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    checkName();
    checkOccupation();
    checkPhoneNo();
    checkState();
    checkCity();
    checkExperience();
    if (
      name &&
      mobNo &&
      occupation &&
      stateFrom &&
      city &&
      experience &&
      nameValidationMsg === "" &&
      occupationValidationMsg === "" &&
      phoneValidationMsg === "" &&
      cityValidationMsg === "" &&
      stateValidationMsg === "" &&
      expDescValidationMsg === ""
    ) {
      setLoadingStatus("loading");
      submitData();
    }
  }

  function submitData() {
    (async () => {
      try {
        const { status } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/partner/signup`,
          {
            partner: {
              name,
              mobileNumber: mobNo,
              primaryOccupation: occupation,
              experience,
              experienceDesc: expDesc,
              state: stateFrom,
              city,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              agreedToTerms: termsAgreed,
              salesPersonId
            },
            salesPersonId: salesPersonId,
            source_adv,
          }
        );
        if(status === 200) {
          setLoadingStatus("done")
          navigate("/thankyou")
        }
      } catch (error) {
        console.log(error);
        setLoadingStatus("done")
        toast({
          title: "Something went wrong!!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      }
    })();
  }

  return (
    <form
      className={cn(styles["home__form"])}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <InputText
        type="text"
        id="FirstName"
        name="FirstName"
        maxlength="100"
        autocomplete="off"
        placeholder="Name"
        className="mt-05"
        value={name}
        onChange={handleNameInput}
        onBlur={checkName}
      />
      {nameValidationMsg && (
        <p className="validation-message">{nameValidationMsg}</p>
      )}
      <br />
      <InputText
        type="text"
        id="Phone"
        name="Phone"
        maxlength="50"
        autocomplete="off"
        placeholder="Mobile number"
        className="mt-05"
        value={mobNo}
        onChange={handleMobNoInput}
        onBlur={checkPhoneNo}
      />
      {phoneValidationMsg && (
        <p className="validation-message">{phoneValidationMsg}</p>
      )}
      <br />
      <InputText
        type="text"
        id="JobTitle"
        name="JobTitle"
        maxlength="50"
        autocomplete="off"
        placeholder="Primary Occupation"
        className="mt-05"
        value={occupation}
        onChange={handleOccupationInput}
        onBlur={checkOccupation}
      />
      {occupationValidationMsg && (
        <p className="validation-message">{occupationValidationMsg}</p>
      )}
      <br />
      <InputText
        type="text"
        id="mx_Existing_or_past_experience_in_education"
        name="mx_Existing_or_past_experience_in_education"
        maxlength="50"
        autocomplete="off"
        placeholder="Existing or Past Experience in Education (Yes/No)"
        className="mt-05"
        value={experience}
        onChange={handleExperienceInput}
        onBlur={checkExperience}
      />
      {expValidationMsg && (
        <p className="validation-message">{expValidationMsg}</p>
      )}
      {experience.toLowerCase() === "yes" && (
        <InputText
          type="text"
          id="mx_Please_tell_us_about_your_experience"
          name="mx_Please_tell_us_about_your_experience"
          maxlength="50"
          autocomplete="off"
          placeholder="If yes, please tell us about your experience"
          className="mt-05"
          value={expDesc}
          onChange={handleExpDescInput}
          onBlur={checkExpDesc}
        />
      )}
      {expDescValidationMsg && (
        <p className="validation-message">{expDescValidationMsg}</p>
      )}
      <br />
      <InputText
        type="text"
        id="mx_City"
        name="mx_City"
        maxlength="50"
        autocomplete="off"
        placeholder="City"
        className="mt-05"
        value={city}
        onChange={handleCityInput}
        onBlur={checkCity}
      />
      {cityValidationMsg && (
        <p className="validation-message">{cityValidationMsg}</p>
      )}
      <br />
      <InputSelect
        id="mx_State"
        name="mx_State"
        value={stateFrom}
        onChange={handleStateInput}
        onBlur={() => checkState()}
        className="mt-05"
      >
        <option value="" selected hidden>
          Select State
        </option>
        {statesAndUts.map((stut) => {
          return (
            <option key={stut} value={stut}>
              {stut}
            </option>
          );
        })}
      </InputSelect>
      {stateValidationMsg && (
        <p className="validation-message">{stateValidationMsg}</p>
      )}
      <span className={cn(styles["tc"])}>
        <label htmlFor="MailingPreferences">
          Customer Agreement and Consent
        </label>
        <br />
        <span className={cn(styles["tc-input"])}>
          <input
            type="checkbox"
            id="MailingPreferences"
            name="MailingPreferences"
            checked={termsAgreed}
            onChange={handleAgreedTerms}
            className="mr-05"
          />
          <label htmlFor="MailingPreferences">T&amp;C and Privacy Policy</label>
        </span>
        <label htmlFor="MailingPreferences">
          I agree to the{" "}
          <a
            href="https://learnhat.com/terms"
            target="_blank"
            rel="noreferrer"
            className="color--blue"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            href="https://learnhat.com/privacy"
            target="_blank"
            rel="noreferrer"
            className="color--blue"
          >
            Privacy Policy
          </a>{" "}
          of LearnHat.
        </label>
      </span>
      <br />
      <PurpleButton id="form-submit-button" type="submit" btnType="btn--large">
        Submit
      </PurpleButton>
      <br />
    </form>
  );
};

export default Form;
